@import "style-guide";

$tooltip-background-white: $white;
$tooltip-background-black: $charcoal;

.children {
  cursor: pointer;
}

.wrap {
  position: relative;

  &--inline {
    display: inline-block;
    margin-left: $u;
  }

  &--show {
    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-left: $u solid transparent;
      border-bottom: $u solid transparent;
      border-right: $u solid transparent;
      border-top: $u solid transparent;
      z-index: $z-index-tooltip;
    }
  }

  &--position-center-left-theme-white {
    &::after {
      left: -2*$u;
      top: 50%;
      margin-top: -$u;
      border-left-color: $tooltip-background-white;
    }
  }

  &--position-center-left-theme-black {
    &::after {
      left: -2*$u;
      top: 50%;
      margin-top: -$u;
      border-left-color: $tooltip-background-black;
    }
  }

  &--position-center-right-theme-white {
    &::after {
      right: -2*$u;
      top: 50%;
      margin-top: -$u;
      border-right-color: $tooltip-background-white;
    }
  }

  &--position-center-right-theme-black {
    &::after {
      right: -2*$u;
      top: 50%;
      margin-top: -$u;
      border-right-color: $tooltip-background-black;
    }
  }

  &--position-top-center-theme-white,
  &--position-top-left-theme-white,
  &--position-top-right-theme-white {
    &::after {
      top: -2*$u;
      left: 50%;
      margin-left: -$u;
      border-top-color: $tooltip-background-white;
    }
  }

  &--position-bottom-left-theme-white,
  &--position-bottom-center-theme-white {
    &::after {
      bottom: -2*$u;
      left: 50%;
      margin-left: -$u;
      border-top-color: $tooltip-background-white;
      transform: rotate(180deg);
    }
  }

  &--position-bottom-right-theme-white {
    &::after {
      bottom: -2*$u;
      left: 90%;
      margin-left: -$u;
      border-top-color: $tooltip-background-white;
      transform: rotate(180deg);
    }
  }

  &--position-top-center-theme-black,
  &--position-top-left-theme-black,
  &--position-top-right-theme-black {
    &::after {
      top: -2*$u;
      left: 50%;
      margin-left: -$u;
      border-top-color: $tooltip-background-black;
    }
  }

  &--position-bottom-left-theme-black,
  &--position-bottom-center-theme-black {
    &::after {
      bottom: -2*$u;
      left: 50%;
      margin-left: -$u;
      border-top-color: $tooltip-background-black;
      transform: rotate(180deg);
    }
  }

  &--position-bottom-right-theme-black {
    &::after {
      bottom: -2*$u;
      left: 90%;
      margin-left: -$u;
      border-top-color: $tooltip-background-black;
      transform: rotate(180deg);
    }
  }
}

.tooltip {
  position: absolute;
  z-index: $z-index-tooltip;

  &--position {
    &-top-center {
      left: 50%;
      top: 0;
      transform: translate(-50%, -100%);
    }

    &-top-left {
      left: 0;
      top: 0;
      transform: translate(0, -100%);
    }

    &-top-right {
      left: 100%;
      top: 0;
      transform: translate(-100%, -100%);
    }

    &-bottom-right {
      left: 100%;
      top: 100%;
      transform: translate(-100%, 0);
    }

    &-center-left {
      left: 0;
      top: 50%;
      transform: translate(-100%, -50%);
    }

    &-center-right {
      left: 100%;
      top: 50%;
      transform: translate(0, -50%);
    }

    &-bottom-center {
      left: 50%;
      top: 100%;
      transform: translate(-50%, 0);
    }

    &-bottom-left {
      left: 0;
      top: 0;
      transform: translate(0, 100%);
    }
  }

  &__inner {
    position: relative;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 $shadow-for-dark-base;
    margin: 2*$u;

    &--position {
      &-top-left {
        margin-left: 0;
      }

      &-bottom-left {
        margin-left: 0;
      }

      &-bottom-right {
        margin-right: 0;
      }
    }

    &--theme {
      &-white {
        background-color: $tooltip-background-white;
      }

      &-black {
        background-color: $tooltip-background-black;

        @include TooltipDark-font;
      }
    }
  }
}
