@import "style-guide";

.inner {
  padding: 2*$u;

  &--theme-black {
    @include TooltipDark-font;
  }

  &--no-wrap {
    white-space: nowrap;
  }

  &--is-center {
    text-align: center;
  }
}
