$charcoal: #3e3f42;
$slate: #6b6c6f;
$slate-40-percent: rgba(107, 108, 111, 0.4);
$gray: #9ea0a5;
$light-light: #fbfbfd;
$light: #e2e5ed;
$transparent-light-70-percent: rgba(226, 229, 237, 0.7);
$light-50-percent: #f0f2f6;
$transparent-light-50-percent: rgba(226, 229, 237, 0.5);
$light-30-percent: rgba(226, 229, 237, 0.3);
$light-30-percent-non-alpha: #f7f8fa;
$light-10-percent: rgba(226, 229, 237, 0.1);
$light-5-percent: rgba(226, 229, 237, 0.05);
$dark: #252529;
$brand-accent: #8aba2e;
$brand-50-percent: rgba(138, 186, 46, 0.5);
$brand-30-percent: rgba(138, 186, 46, 0.3);
$brand-20-percent: rgba(138, 186, 46, 0.2);
$brand-5-percent: rgba(138, 186, 46, 0.05);
$brand-3-percent: rgba(138, 186, 46, 0.03);
$dodger-blue: #32c5ff;
$azul: #1665d8;
$maize: #facf55;
$purpley: #6758f3;
$squash: #f6a623;
$medium-green: #39b54a;
$blaze-orange: #fa6400;
$tomato-red: #d32406;
$tomato-red-50-percent: rgba(211, 36, 6, 0.5);
$tomato-red-10-percent: rgba(211, 36, 6, 0.1);
$white: #fff;
$white-10-percent: rgba(255, 255, 255, 0.1);
$white-40-percent: rgba(255, 255, 255, 0.4);
$black: #000;
$black-5-percent: rgba(0, 0, 0, 0.05);
$black-10-percent: rgba(0, 0, 0, 0.1);
$black-20-percent: rgba(0, 0, 0, 0.2);
$black-30-percent: rgba(0, 0, 0, 0.3);
$brand-dark: #4b6518;
$shadow-for-dark-base: rgba(0, 0, 0, 0.2);

// not style guide
$orange: #ea9200;
$orange-10-percent: rgba(234, 146, 0, 0.1);
$orange-50-percent: rgba(234, 146, 0, 0.5);
