/* outside */
@mixin base-font {
  font-family: "Roboto", sans-serif;
}

@mixin secondary-font {
  font-family: "Roboto Condensed", sans-serif;
}

@mixin Text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin Fix-Inner-For-Flex-Text-overflow-ellipsis {
  min-width: 0;
}

@mixin Avatar-Normal-font {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.12;
  color: $white;
}

@mixin Account-Avatar-Normal-font {
  font-size: 22px;
  font-weight: bold;
  line-height: 1.36;
  color: $charcoal;
}

@mixin Account-Email-font {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: $brand-dark;
}

@mixin Account-Description-font {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  color: $gray;
}

/* outside */
@mixin Auth-Title-font {
  font-weight: bold;
  font-size: 20px;
  line-height: 1.5;
  color: $charcoal;
}

@mixin Title-Widget-font {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.12;
  color: $charcoal;
}

@mixin Title-Card-font {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.12;
  color: $charcoal;
}

@mixin Modal-Title-font {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.12;
  color: $charcoal;
}

@mixin Modal-Text-font {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
  color: $charcoal;
}

@mixin Modal-Text-Secondary-font {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.25;
  color: $charcoal;
}

@mixin Calendar-font {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: $charcoal;
}

@mixin Calendar-weekday-font {
  color: $gray;
  font-size: 10px;
  line-height: 1;
  font-weight: bold;
}

@mixin Tab-Normal-font {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  color: $slate;
}

@mixin Tab-Small-font {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: $slate;
}

@mixin SideBar-InnerLeftMenu-Item-Normal-font {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: $charcoal;
}

@mixin SideBar-Loader-Message-font {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
}

@mixin SideBar-LeftSideMenu-ListItem-Normal-font {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: $white;
}

@mixin SideBar-LeftSideMenu-ListItem-Hover-font {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: $brand-accent;
}

@mixin Input-Value-Bold-font {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: $charcoal;
}

@mixin Input-Value-Normal-font {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: $charcoal;
}

@mixin PriceDetails-Normal-font {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: $charcoal;
}

@mixin PriceDetails-Money-font {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: $charcoal;
}

@mixin PriceDetails-Placeholder-font {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: $gray;
}

@mixin Input-Description-Normal-font {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.67;
  color: $charcoal;
}

@mixin Utilization-Normal-font {
  font-size: 11px;
  font-weight: bold;
  line-height: 1.82;
  color: $charcoal;
}

@mixin SideBar-LeftSideMenu-Item-Counter-font {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: $gray;
}

@mixin Alert-WhiteMessage-font {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: $white;
}

@mixin Alert-WhiteAction-font {
  font-size: 11px;
  font-weight: bold;
  line-height: 1.82;
  color: $white;
}

@mixin Input-Value-Placeholder-font {
  font-size: 14px;
  line-height: 1.43;
  font-weight: normal;
  color: $gray;
}

@mixin Input-Units-font {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: $charcoal;
}

@mixin Avatar-User-font {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: $slate;
}

@mixin Avatar-Company-font {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  color: $gray;
}

@mixin Menu-Item-font {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: $slate;
}

@mixin Disclaimer-font {
  font-size: 12px;
  line-height: 1.25;
  color: $slate;
}

@mixin Copyright-font {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: $slate;
}

/* outside */
@mixin Error-font {
  font-size: 14px;
  line-height: 1.375rem;
  color: $tomato-red;
}

@mixin Input-Note-Error-font {
  font-size: 14px;
  line-height: 15px;
  font-weight: bold;
  color: $tomato-red;
}

@mixin Input-Note-Warning-font {
  font-size: 14px;
  line-height: 15px;
  font-weight: bold;
  color: $orange;
}

@mixin Input-Note-Success-font {
  font-size: 14px;
  line-height: 15px;
  font-weight: bold;
  color: $brand-accent;
}

@mixin Input-Note-Normal-font {
  font-size: 14px;
  line-height: 1.07;
  font-weight: bold;
  color: $gray;
}

@mixin Input-Label-font {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  color: $slate;
}

@mixin SideBar-Item-Subtitle-Gray-font {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  color: $gray;
}

@mixin Status-Regular-font {
  font-size: 11px;
  font-weight: bold;
  line-height: 20px;
}

@mixin TextBig-price-font {
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
}

@mixin Comment-font {
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
  line-height: 1.43;
}

@mixin Dropdown-ListNormal-font {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: $charcoal;
}

@mixin Dropdown-ListBold-font {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: $charcoal;
}

@mixin TagSmall-Label-font {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.67;
  color: $white;
}

@mixin TitleEmpty-State-font {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.12;
  color: $charcoal;
}

@mixin Table-Header-font {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.67;
  color: $slate;
}

@mixin Table-Row-font {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: $charcoal;
}

@mixin Table-Row-small-font {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.67;
  color: $charcoal;
}

@mixin Table-Row-Secondary-font {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  color: $slate;
}

@mixin Table-Row-Secondary-small-font {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.43;
  color: $slate;
}

@mixin Notification-Number-font {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.67;
  letter-spacing: 1px;
  color: $white;
}

@mixin Link-Label-font {
  font-size: 14px;
  line-height: 1.43;
}

@mixin Small-Link-Label-font {
  font-size: 12px;
  line-height: 1.67;
}

@mixin Notification-Status-font {
  font-size: 11px;
}

@mixin Notification-Item-font {
  font-size: 14px;
  line-height: 1.43;
}

@mixin Notification-Card-font {
  font-size: 16px;
}

@mixin TagSmallWhite-Label-font {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.67;
  letter-spacing: normal;
  color: $white;
}

@mixin TableRowPrimary-Link-font {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: $brand-dark;
}

@mixin TextNotification-Base-font {
  font-size: 14px;
  line-height: 1.43;
  color: $white;
}

@mixin TextNotification-Text-font {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  color: $white;
}

@mixin TooltipDark-font {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.25;
  color: $light;
}

@mixin Country-Text-font {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: $charcoal;
}

@mixin Map-Text-Bold-font {
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
}

@mixin TextBig-status-font {
  font-size: 14px;
  font-weight: bold;
  line-height: 2.14;
  color: $charcoal;
}

@mixin SellPrice-font {
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
}

@mixin Timeline-font {
  font-size: 10px;
  font-weight: bold;
  line-height: 1;
  color: $gray;
}

@mixin TimeWindow-font {
  font-size: 11px;
  font-weight: bold;
  line-height: 1.43;
  color: $white;
}

@mixin Asset-Pin-Utilization-font {
  font-size: 11px;
  font-weight: bold;
  line-height: 20px;
}

@mixin Asset-Pin-Arrival-font {
  font-size: 9px;
  font-weight: bold;
  line-height: 1.11;
}

@mixin Asset-Pin-Light-Text-font {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
  color: #fff;
}

@mixin Asset-Pin-Dark-Text-font {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
  color: $slate;
}

@mixin Info-panel-title-font {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: $charcoal;
}

@mixin Info-panel-content-font {
  font-size: 13px;
  font-weight: normal;
  line-height: 1.54;
  color: $charcoal;
}

@mixin Notification-time-font {
  font-size: 11px;
  font-weight: bold;
  line-height: 1.82;
  color: $charcoal;
}

@mixin SideBar-Message-title-font {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.12;
  color: $charcoal;
}

@mixin SideBar-Message-description-font {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
  color: $charcoal;
}

@mixin Route-Estimate-font {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
}

@mixin SideBar-Offer-price-font {
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
}

@mixin Transparent-Button-font {
  font-size: 11px;
  font-weight: bold;
  line-height: 1.82;
  color: $white;
}

@mixin Info-table-name-normal-font {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: $charcoal;
}

@mixin Info-table-value-normal-font {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: $charcoal;
}
