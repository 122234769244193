$z-index-navigation: 1000;
$z-index-loader-overlay: 100;
$z-index-sidebar: 102;
$z-index-sticky-footer: 101;
$z-index-notifications-panel: 199;
$z-index-alerts-panel: 199;
$z-index-dev-localization-language-panel: 199;
$z-index-error-panel: 200;
$z-index-overlay: 106;
$z-index-date-picker: 5;
$z-index-form-top-bar: 4;
$z-index-form-paranja: 4;
$z-index-form-bottom-bar: 4;
$z-index-form-map: 5;
$z-index-price-matrix-tooltip: 2;
$z-index-tooltip: 103;
$z-index-map-tooltip: 2;
$z-index-map-pin-hover: 1;
$z-index-range-selector-1: 1;
$z-index-range-selector-2: 2;
$z-index-range-selector-3: 3;
$z-index-form-overlay: 4;
$z-index-form-overlay-loader: 5;
