@import "style-guide";

body {
  position: relative;
  background-color: $light-light;

  @include base-font;

  color: $charcoal;

  // dashboard-ui styles reset
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

svg {
  vertical-align: top;
}

a {
  text-decoration: none;
  cursor: pointer;
}

input::placeholder {
  color: $gray;
}
