@import "style-guide";

.option {
  padding: 2*$u 3*$u;
  cursor: pointer;
  white-space: nowrap;

  @include Text-overflow-ellipsis;
  @include Dropdown-ListBold-font;

  &:hover {
    background-color: $optionHoverBackgroundColor;
  }

  &--isSelected {
    background-color: $inputHoverBackgroundColor;

    &:hover {
      background-color: $inputHoverBackgroundColor;
    }
  }
}

.separator {
  background-color: $light-30-percent;
  height: 2 * $u;
}

.overlay {
  min-width: 100%;
}
