@mixin control-shadow {
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.1);
}

@mixin control-shadow-on-map {
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.1);
}

@mixin regular-shadow {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}

@mixin top-bar-shadow {
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
}

@mixin sidebar-shadow {
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
}
