@import "style-guide";

.trigger {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  border-radius: $controlDefaultBorderRadius;
  user-select: none;
  transition: background-color $fastAnimationDuration;

  &--withoutBorderRadius {
    border-radius: 0;
  }

  &--isClickable {
    cursor: pointer;
  }

  &--reflection-theme-half-transparent-light.trigger {
    &--isClickable {
      &:hover {
        background-color: $transparent-light-50-percent;
      }

      &:active {
        background-color: $transparent-light-70-percent;
      }
    }

    &--isPressed {
      background-color: $transparent-light-70-percent;

      &:hover {
        background-color: $transparent-light-70-percent;
      }
    }
  }

  &--reflection-theme-light.trigger {
    &--isClickable {
      &:hover {
        background-color: $light-50-percent;
      }

      &:active {
        background-color: $light;
      }
    }

    &--isPressed {
      background-color: $light;

      &:hover {
        background-color: $light;
      }
    }
  }

  &--isToggled {
    background-color: $inputFocusBackgroundColor;
  }

  &--spaces-xxs {
    padding: 0;

    &.trigger--hasLeftIconWithLabel {
      padding-left: 0;
    }

    &.trigger--hasRightIconWithLabel {
      padding-right: 0;
    }

    &.trigger--isOnlyIcon {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--spaces-xs {
    padding: $u 2*$u;

    &.trigger--hasLeftIconWithLabel {
      padding-left: $u;
    }

    &.trigger--hasRightIconWithLabel {
      padding-right: $u/2;
    }

    &.trigger--isOnlyIcon {
      padding-left: $u;
      padding-right: $u;
    }
  }

  &--spaces-s {
    padding: 2*$u 3*$u;

    &.trigger--hasLeftIconWithLabel {
      padding-left: 2*$u;
    }

    &.trigger--hasRightIconWithLabel {
      padding-right: $u;
    }

    &.trigger--isOnlyIcon {
      padding-left: 2*$u;
      padding-right: 2*$u;
    }
  }

  &__label {
    @include Input-Value-Normal-font;

    flex-grow: 1;

    &--spaces-xxs {
      &.trigger__label--hasLeftIcon {
        padding-left: 0;
      }

      &.trigger__label--hasRightIcon {
        padding-right: 0;
      }
    }

    &--spaces-xs {
      &.trigger__label--hasLeftIcon {
        padding-left: $u;
      }

      &.trigger__label--hasRightIcon {
        padding-right: $u;
      }
    }

    &--spaces-s {
      &.trigger__label--hasLeftIcon {
        padding-left: 2*$u;
      }

      &.trigger__label--hasRightIcon {
        padding-right: 2*$u;
      }
    }
  }

  &__icon {
    height: 4*$u;
    min-width: 4*$u;
  }
}
