@import "style-guide";

$defaultVerticalPadding: 2*$u;
$defaultHorizontalPadding: 3*$u;
$defaultNarrowHorizontalPadding: 2*$u;

@mixin cleanButtonStyles {
  box-shadow: none;
  border: none;
}

.button {
  position: relative;
  outline: none;
  padding: $defaultVerticalPadding $defaultHorizontalPadding;
  border: none;
  border-radius: $controlDefaultBorderRadius;

  @include Input-Value-Normal-font;
  @include base-font;

  &--theme-primary {
    color: $white;
    background-color: #7ca829;

    &.button--isFocused,
    &:hover {
      background-color: #759e27;
    }

    &.button--isPressed,
    &:active {
      background-color: #678c22;
    }
  }

  &--theme-secondary {
    color: $charcoal;
    background-color: $light-30-percent-non-alpha;

    &.button--isFocused,
    &:hover {
      background-color: $light-50-percent;
    }

    &.button--isPressed,
    &:active {
      background-color: $light;
    }
  }

  &--theme-danger {
    color: #fff;
    background-color: #d32406;

    &.button--isFocused,
    &:hover {
      background-color: #c92205;
    }

    &.button--isPressed,
    &:active {
      background-color: #be2005;
    }
  }

  &--theme-secondary-danger {
    color: $tomato-red;
    background-color: #d3240619;

    &.button--isFocused,
    &:hover {
      background-color: #d3240626;
    }

    &.button--isPressed,
    &:active {
      background-color: #d3240633;
    }
  }

  &--theme-transparent {
    color: $charcoal;
    background-color: transparent;

    &.button--isFocused,
    &:hover {
      background-color: $light-50-percent;
    }

    &.button--isPressed,
    &:active {
      background-color: $light;
    }
  }

  &--isClickable {
    cursor: pointer;
  }

  &--isDisabled {
    background-color: $slate !important;
    color: $white;

    @include cleanButtonStyles;

    &.button--isFocused,
    &:hover {
      @include cleanButtonStyles;
    }

    &.button--isPressed,
    &:active {
      @include cleanButtonStyles;
    }
  }

  &--isToggled {
    background-color: $inputFocusBackgroundColor !important;
    color: $brand-dark;

    @include cleanButtonStyles;

    &:hover {
      @include cleanButtonStyles;
    }

    &.button--isPressed,
    &:active {
      @include cleanButtonStyles;
    }
  }

  &--isFullWidth {
    width: 100%;
  }

  &--isNarrowLeftPadding {
    padding-left: $defaultNarrowHorizontalPadding;
  }

  &--isNarrowRightPadding {
    padding-right: $defaultNarrowHorizontalPadding;
  }
}

.icon {
  display: inline-block;
  vertical-align: top;
  height: 4*$u;

  &--isLeft {
    padding-right: 2*$u;
  }

  &--isRight {
    padding-left: 2*$u;
  }

  &--isHidden {
    opacity: 0;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text {
  display: inline-block;
  vertical-align: top;

  &--isHidden {
    opacity: 0;
  }
}

.inner {
  white-space: nowrap;

  &--isFullWidth {
    display: flex;
    justify-content: space-between;
  }
}
