@import "style-guide";

.select {
  &--full-size {
    display: flex;
  }
}

.option {
  position: relative;
  padding-left: 28px;
  display: flex;
  align-items: center;
}
