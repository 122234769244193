@import "style-guide";

.dots {
  display: inline-block;
  width: 70px;
  text-align: center;

  .dot {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    vertical-align: middle;
    display: inline-block;
    animation: bounce 1s infinite ease-in-out both;

    &--1 {
      animation-delay: -0.32s;
      margin-right: 2px;
    }

    &--2 {
      animation-delay: -0.16s;
      margin-right: 2px;
    }

    &--theme-light {
      background-color: $light-light;
    }

    &--theme-dark {
      background-color: $charcoal;
    }
  }
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}
