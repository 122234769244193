@import "style-guide";

.wrap {
  position: relative;

  &--is-inline {
    display: inline-block;
  }
}

.overlay {
  position: absolute;
  background: $white;

  @include control-shadow;

  z-index: $z-index-overlay;
  border-radius: $u;
  animation: fadeIn 0.1s ease-in;

  &--has-scroll {
    max-height: 60*$u;
    overflow-y: auto;
  }

  &--position-bottom-left {
    top: 100%;
    left: 0;
  }

  &--position-bottom-right {
    top: 100%;
    right: 0;
  }

  &--position-bottom-center {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &--position-top-center {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &--position-top-center-trigger {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &--position-top-left {
    bottom: 100%;
    left: 0;
  }

  &--position-top-right {
    bottom: 100%;
    right: 0;
  }
}
