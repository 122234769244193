@import "colors";
@import "fonts";
@import "shadows";
@import "spaces";
@import "z-index";
@import "form";
@import "sidebar";
@import "fixes";
@import "skeletons";
@import "tables";
@import "map";

$fastAnimationDuration: 0.2s;
$defaultAnimationDuration: 0.3s;
$slowAnimationDuration: 0.5s;

@keyframes blinking {
  0% { opacity: 0.1; }
  50% { opacity: 0.2; }
  100% { opacity: 0.1; }
}

@mixin background-loader {
  opacity: 0.2;
  background-color: $light;
  animation: blinking 1s infinite;
}

$maxWidthLimit: 1340px;

@mixin width-limit {
  max-width: $maxWidthLimit;
}

@mixin max-page-width-limit {
  @include width-limit;

  margin: 0 auto;
}

$minHeaderHeight: 14*$u;

@mixin header-title-font {
  @include secondary-font;
  @include Title-Widget-font;

  text-transform: uppercase;
}

// TODO add more pixel ratio
// $pixelRatios: 1, 1.5, 2, 2.5, 3;
$pixelRatios: 1;

@mixin content-margins {
  padding: 0 6*$u;

  // TODO add media query
}

$widthMenuTrigger: 14*$u;

@mixin sticky-content-margins {
  padding: 0 4*$u;
  margin: 0 2*$u;

  @each $pixelRatio in $pixelRatios {
    // TODO add (-webkit-device-pixel-ratio: $pixelRatio)
    @media screen and (max-width: #{1668px * $pixelRatio}) {
      margin: 0;
      padding: 0 6*$u;
    }

    @media screen and (max-width: #{768px * $pixelRatio}) {
      margin: 0;
      padding: 0 6*$u 0 $widthMenuTrigger;
    }
  }
}

@mixin overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -4*$u;
  right: -4*$u;
  z-index: $z-index-form-overlay;
  background-color: $white;
  opacity: 0.9;
}
