$formControlBorderWidth: 2px;

@mixin field-label {
  @include Input-Label-font;

  text-transform: uppercase;
}

$inputSuccessBorderColor: #8aba2e;
$inputWarningBorderColor: #ea9200;
$inputErrorBorderColor: #d32406;
$inputBorderWidth: 2px;
$inputDefaultBackgroundColor: #f9f9f9;
$inputHoverBackgroundColor: #f8f8f9;
$inputFocusBackgroundColor: #f4f8e8;
$labelDefaultBackgroundColor: #f9f9f9;
$optionHoverBackgroundColor: #fdfdfe;
$controlDefaultBorderRadius: 4px;
$optionPhoneNumber: #fff;
$optionHoverPhoneNumber: #fdfdfe;
$optionSelectedPhoneNumber: #f7f8fa; // $light-30-percent-non-alpha
$inputPressedBackgroundColor: #e2e5ed; // light
$inputDisabledBackgroundColor: #e1e1e3; // not-alpha black-10
$inputDisabledColor: #6b6c6f; // slate

@mixin left-field-in-group {
  border-radius: $controlDefaultBorderRadius 0 0 $controlDefaultBorderRadius !important; // rewrite input property
  margin-right: $inputBorderWidth/2;
}

@mixin right-field-in-group {
  border-radius: 0 $controlDefaultBorderRadius $controlDefaultBorderRadius 0 !important; // rewrite input property
  margin-left: $inputBorderWidth/2;
}
