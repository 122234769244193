@import "style-guide";

$base-width: 280px;

.container {
  display: flex;
  height: 100%;
}

.left {
  flex-grow: 1;
  overflow: hidden;
}

.right {
  background-color: $white;
  width: 50%;
  min-width: $base-width;
  height: 100%;
  padding: 0 4*$u;
  overflow-y: scroll;
}

@media only screen and (max-width: 2*$base-width) {
  .left {
    display: none;
  }

  .right {
    width: 100%;
  }
}

.background {
  width: 100%;
  height: 100%;
  transition: all $defaultAnimationDuration;
  background-color: #ececec;
  background-size: cover;
  background-position: center;
  background-image: url(./images/left.png);

  &:hover {
    transform: scale(1.01);
  }
}

/* stylelint-disable media-feature-name-no-unknown */
@media 	only screen and (minpixel-ratio: 1.5),
  only screen and (minpixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 3/2),
  only screen and (min-resolution: 1.5dppx),
  only screen and (min-resolution: 144dpi) {
  .background {
    background-image: url(./images/left@2x.png);
  }
}
/* stylelint-enable media-feature-name-no-unknown */

.inner {
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;

  &-inner {
    margin: 0 auto;
    padding: 20*$u 0;

    @media only screen and (max-width: 600px) {
      padding: 8*$u 0;
    }
  }
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: $charcoal;
  text-align: center;
  margin-bottom: 6*$u;
}

.content {
  flex-grow: 1;

  &-inner {
    max-width: $base-width;
    margin: 0 auto;
    height: 100%;
  }
}

.footer {
  display: flex;
  align-items: flex-end;
  color: $charcoal;
  line-height: 16px;
  font-size: 12px;

  &-inner {
    margin: 0 auto;
    padding: 4*$u 0;
  }
}

.language-switcher {
  margin-bottom: 4*$u;
}
