@import "style-guide";

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.icon {
  margin-bottom: 2*$u;
  text-align: center;
}

.title {
  @include Modal-Title-font;

  margin-bottom: 4*$u;
  text-align: center;
}

.description {
  @include Modal-Text-font;

  text-align: center;
}

.actions {
  margin-top: 8*$u;
  display: flex;
  justify-content: center;
  gap: 4 * $u;
}
