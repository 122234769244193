@import "style-guide";

$borderWidth: 2px;

.trigger {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  cursor: pointer;
  user-select: none;
  min-height: 8*$u;
  padding: 0 $u 0 ((3*$u) - $borderWidth);
  border: solid $borderWidth transparent;
  background-color: $labelDefaultBackgroundColor;
  border-radius: $controlDefaultBorderRadius;

  &--withLeftIcon {
    padding-left: ((2*$u) - $borderWidth);
  }

  &--isEmpty {
    border-color: transparent;
  }

  &:hover {
    background-color: $inputHoverBackgroundColor;
  }

  &--isFocus {
    background-color: $inputFocusBackgroundColor;
    border-color: transparent;
  }

  &--isPressed {
    background-color: $inputPressedBackgroundColor;
    border-color: $inputPressedBackgroundColor;

    &:hover {
      background-color: $inputPressedBackgroundColor;
    }
  }

  &--isDisabled {
    background-color: $inputDisabledBackgroundColor;
    border-color: $inputDisabledBackgroundColor;
    cursor: not-allowed;

    &:hover {
      background-color: $inputDisabledBackgroundColor;
    }
  }

  &--hasWarning {
    border-color: $inputWarningBorderColor;
  }

  &--hasSuccess {
    border-color: $inputSuccessBorderColor;
  }

  &--hasChanges {
    border-color: $inputWarningBorderColor;
  }

  &--hasError {
    border-color: $inputErrorBorderColor;
  }

  &__label {
    flex-grow: 1;

    @include Dropdown-ListBold-font;
    @include Fix-Inner-For-Flex-Text-overflow-ellipsis;

    &--isEmpty {
      @include Input-Value-Placeholder-font;
    }

    &--isDisabled {
      color: $inputDisabledColor;
    }
  }

  $iconSize: 4*$u;

  &__icon {
    &--clear {
      margin-right: $u;
    }

    &--rightIcon {
      margin-right: $u;
    }

    &--leftIcon {
      margin-right: 2*$u;
    }
  }
}
