@import "style-guide";

.flag {
  display: inline-block;
  width: 20px;
  height: 14px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 2px;
  margin: 3px 0;

  &--isEmpty {
    background-color: $light;
  }

  &--hasCountryCode {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  }
}

.wrap {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
  height: 4*$u;
}
